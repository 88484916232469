import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <section className="h-[26vh] bg-slate-500 w-[100%] md:h-[55vh]">
      <article className="hidden bg-slate-700 text-center relative top-[10vh] translate-y--1/2 w-full p-11">
        <h2 className="text-4xl md:text-9xl text-slate-100">Not Found</h2>
        <p className="text-slate-200 md:text-xl mt-5">The requested URL was not found on this server.
          <br />
          If you entered the URL manually please check your spelling and try again.
          <br />
          If you think this is a server error, please
          <a className="text-sky-500" href="/contact"> contact</a> the webmaster.
          Otherwise, click <Link className="text-sky-500" to="/">here</Link> to continue.
        </p>
      </article>
      <article className="bg-slate-700 text-center relative top-[2vh] translate-y--1/2 w-full p-2">
        <h2 className="text-4xl md:text-9xl text-slate-100">Not Found</h2>
        <p className="text-slate-200 md:text-xl mt-5">The requested URL was not found on this server.
        </p>
        <p>
          Follow the link back to the Home Page
          <br />
          <Link className="text-3xl text-sky-500" to="/">here</Link>
        </p>
      </article>
    </section>
  )
}

export default NotFound