import React, { useEffect, useState } from 'react'
//get url params
import { useParams, Link } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';

function Project(props) {
  const { project, showProject } = props;
  const { name, description, screenshots, liveDemo, features, technologies, github } = project;


  const { id } = useParams();
  const [images, setImages] = useState([]);
  const [techs, setTechs] = useState([]);


  const path = `/res/project${parseInt(id) + 1}/screenshots/`;

  useEffect(() => {
    showProject(id);
    setImages(makeSources(screenshots, path));
    if (technologies === undefined || technologies === null || technologies.length === 0) {
      setTechs([]);
    } else {
      setTechs(technologies);
    }
  }, [id, project]);

  if (project === undefined || project === {} || project === null) {
    return <div>loading...</div>
  } else {
    return (
      <section>
        <Link to="/">
          <i className="invisible text-6xl text-neutral-100 px-1 bg-neutral-600 absolute md:visible">
            <BiArrowBack className="text-3xl text-neutral-100 " />
          </i>
        </Link>
        <article className='md:pl-14'>
          <h2 className="text-4xl">{name}</h2>
          <div className='md:flex md:justify-between my-3 md:items-center md:content-center'>
            <p className='my-3'>{description}</p>
            <div className='mb-3 flex flex-col md:flex-row md:justify-between md:items-center md:content-center border-sky-900 border-4'>
              <a href={liveDemo} target="_blank" rel="noreferrer" className='text-center bg-sky-900 border-slate-400 border-2 text-xl px-3 hover:cursor-pointer hover:bg-sky-800 '>Live Demo</a>
            </div>
            <div className='mb-3 flex flex-col md:flex-row md:justify-between md:items-center md:content-center border-purple-800 border-4'>
              <a href={github} target="_blank" rel="noreferrer" className='text-center bg-purple-900 border-slate-400 border-2 text-xl px-3 hover:cursor-pointer hover:bg-purple-800 '>GitHub</a>
            </div>
          </div>
          <p className='font-bold'>Technologies:</p>
          <div className={`flex flex-wrap justify-between ${(techs.length > 6) ? 'md:max-w-md' : 'md:max-w-xs'}`}>
            {(techs) ? techs.map((tech, index) => <p key={index} className='bg-neutral-700 px-2 mr-1 my-2'>{`${tech}`}</p>) : ""}
          </div>
        </article>
        <article className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-5">
          {(images.length > 0) ? images.map((image, index) => (
            <img key={index} src={image} alt={name} className="w-full h-64 hover:transition-transform hover:duration-200 hover:scale-110"></img>
          )) : ""}
          <p className='sm:p-4'><span className='font-bold'>Features:</span> {features}</p>
        </article>
      </section>
    )
  }
}


const makeSources = (data, path) => {
  if (data === undefined) return [];
  const sources = [];
  for (let i = 0; i < data.length; i++) {
    sources.push(`${path}${data[i]}`);
  }
  return sources;
}

export default Project;