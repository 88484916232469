import React from 'react'
import { Link } from 'react-router-dom'

const Thanks = () => {
  return (
    <section>
      <h2 className='text-3xl font-bold mb-5'>Message was sent!</h2>
      <article>
        <p>Thank you for your message. I will get back to you as soon as possible.</p>
        <p>If you're still interested in seeing other projects on my site click this link</p>

        <Link to="/" className="">
          <p className='text-center text-2xl bg-neutral-600 rounded py-1 mt-3 text-sky-500'>Home</p>
        </Link>
      </article>
    </section>
  )
}

export default Thanks