import React from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ProjectItem = (props) => {
  const { project } = props;
  const { name, id, description, screenshots, liveDemo } = project;
  const projNum = id + 1;
  return (
    <div className="bg-neutral-700 rounded-lg mb-5 relative hover:scale-110 hover:z-10 transition-transform ease-in-out duration-200" >
      <Link to={`/project/${id}`}>
        <img alt={name} src={`./res/project${projNum}/screenshots/${screenshots[1]}`} className="w-full h-64 rounded-t-lg"></img>
      </Link>
      <div className="mt-3 flex flex-col content-between items-center">
        <p className='min-h-[75px] w-[95%] '>{description}</p>
        <a href={liveDemo} target="_blank" rel="noreferrer" className="bg-cyan-900 px-2 rounded-b-lg w-full block text-center text-lg
        hover:bg-cyan-700">Live Demo</a>
      </div>
    </div>
  )
}

ProjectItem.propTypes = {
  project: PropTypes.object.isRequired
}

export default ProjectItem;