// import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useState } from 'react';

import SideInfo from './components/baseLayout/SideInfo';
import Projects from './components/projects/Projects';
import Project from './components/projects/Project';
import Navbar from './components/baseLayout/Navbar';
import Header from './components/baseLayout/Header';
import Footer from './components/baseLayout/Footer';
import Contact from './components/contact/Contact';
import About from './components/about/About';
import projectData from './components/projects/projectData.json';
import Thanks from './components/contact/Thanks';
import NotFound from './components/NotFound';
import ScrollToTop from './components/misc/ScrollToTop';

function App() {
  const { data } = projectData;
  const [project, setProject] = useState({});

  const showProject = (id) => {
    const project = data[id];
    setProject(project);
  }

  return (
    <Router>
      <div className="App relative max-w-7xl mx-auto">
        <div className="static top-0 flex flex-col flex-grow max-w-7xl ml-1">
          <ScrollToTop />
          <Header />
          <Navbar />

          <main className="md:ml-52 p-10 text-neutral-300 md:min-h-[65vh] flex flex-col align-middle bg-zinc-800">
            <Routes>
              <Route path="/" element={<Projects data={data} />} />
              <Route path="/project/:id" element={<Project project={project} showProject={showProject} />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<About />} />
              <Route path="/thanks" element={<Thanks />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
          <SideInfo />
          <Footer />
        </div>
      </div>
    </Router >
  );
}

export default App;
