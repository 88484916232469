import ProjectItem from "./ProjectItem";
import { Link } from "react-router-dom";

const Projects = (props) => {
  const { data } = props;
  return (
    <section className="md:px-14">
      <article className="flex flex-col items-center">
        <h2 className="text-3xl font-bold text-neutral-100">Projects</h2>
        <p className="text-xl text-neutral-300 leading-7 mt-5">
          These projects were deveolped using pure HTML, CSS, and JavaScript. I am currently learning React and will be adding more projects soon.
          This website was also developed using React. Click on a project to see more details such as screenshots, github repo, and live demo.
        </p>
      </article>
      <article className="mt-7 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 ">
        {data.map((project) => (
          <ProjectItem key={project.id} project={project} />
        ))}
      </article>
      <article className="mt-10">
        <h3 className="text-2xl text-neutral-200">Want to hire me?</h3>
        <p className="mt-5 md:text-lg">
          Hope you like what you see. If you would like to hire me for a project, please send me a message.
          <Link to="/contact">
            <span className="font-bold h-10 mt-4"> HERE.</span>
          </Link>
        </p>
        <Link to="/contact">
          <button className="w-full text-neutral-50 bg-gradient-to-b from-sky-600 to-sky-800 opacity-80 rounded text-xl font-bold h-10 mt-4 md:w-1/4 md:rounded-full hover:opacity-100 active:opacity-75">Message</button>
        </Link>
      </article>
    </section>
  )
}

export default Projects;