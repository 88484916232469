import React from 'react';
import Tag from './Tag';
import { BiArrowBack } from 'react-icons/bi';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <>
      <Link to="/">
        <i className="invisible text-6xl text-neutral-100 px-1 bg-neutral-600 absolute md:visible">
          <BiArrowBack className="text-3xl text-neutral-100 " />
        </i>
      </Link>
      <div className="flex flex-col md:flex-row md:justify-between md:items-center md:px-14">
        <div className="flex flex-col items-center md:w-4/5">
          <h2 className="text-3xl font-bold text-neutral-100">Bio</h2>
          <p className="text-xl text-neutral-300 leading-7 mt-5">I am a full stack web developer with a handful of projects. Most of my projects are HTML, CSS, JS. I've also helped a few freinds with Java projects in school and it's the language I started with. I have interests in game development so C++ is on my list to learn. Tailwind is my new favorite technology for styling my markup. React is my best skill.</p>
        </div>
        <div className="invisible flex flex-col items-center h-full overflow-hidden md:visible md:w-2/6 ">
          <h2 className="text-lg my-3 text-neutral-100">Skills and Technologies</h2>
          <div className="grid grid-cols-3 gap-1">
            <Tag name="HTML" type="FE" />
            <Tag name="CSS" type="FE" />
            <Tag name="JavaScript" type="FE" />
            <Tag name="React" type="FE" />
            <Tag name="Node" type="BE" />
            <Tag name="Express" type="BE" />
            <Tag name="Helmet" type="BE" />
            <Tag name="Mongo" type="DB" />
            <Tag name="SQL" type="DB" />
            <Tag name="Git" type="Tools" />
            <Tag name="GitHub" type="Tools" />
            <Tag name="VS Code" type="Tools" />
            <Tag name="Heroku" type="Tools" />
            <Tag name="Netlify" type="Tools" />
            <Tag name="Tailwind" type="FE" />
            <Tag name="Sass" type="FE" />
            <Tag name="Render" type="Tools" />
          </div>
        </div>
      </div>
    </>
  )
}

export default About;