//send post request to server 
const url = 'https://rexfreelancing.com/josevaz/sendmsg';
// || 'http://localhost:3051/josevaz/sendmsg';
const SendEmail = (submitData) => {
  // console.log(submitData);
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(submitData),
  })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);

    })
    .catch((error) => {
      console.log(error);
      console.error('Error:', error);
    });
}
export default SendEmail;