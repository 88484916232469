import React from 'react'

const footer = () => {
  return (
    <footer className="flex flex-col bg-zinc-800 border-t-2 border-zinc-900 md:ml-52 h-32 p-2 ">
      <p className="text-neutral-300">© 2022 Jose Vazquez</p>
      <p className="text-neutral-300">All Rights Reserved</p>
      {/* <p className="text-neutral-300">Terms of Service</p> */}
    </footer>
  )
}

export default footer
