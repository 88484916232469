import React from 'react'

const Tag = (props) => {
  const { name, type } = props;
  let styles = {
    "FE": "text-sky-400",
    "BE": "text-orange-400",
    "DB": "text-green-400",
    "Tools": "text-purple-400",
    "other": "text-sky-400"
  }

  return (
    <p className={`bg-neutral-700 px-1 rounded-md md:text-sm ${styles[type]}`}>{name}</p>
  )
}

export default Tag;