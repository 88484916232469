import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import SendEmail from './SendEmail';

const Contact = () => {
  const navigate = useNavigate();


  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const changeFunctions = {
    email: setEmail,
    phone: setPhone,
    message: setMessage
  }

  const onChange = (e) => {
    const { name, value } = e.target;
    changeFunctions[name](value);
  }
  const clearState = () => {
    setEmail('');
    setPhone('');
    setMessage('');
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const submitData = {
      email,
      phone,
      message
    }
    clearState();
    //set timeout to allow state to clear before redirect
    // setTimeout(() => {
    // SendEmail(submitData);
    navigate('/thanks');
    // }, 1000);
    SendEmail(submitData);//SENDMAIL is not used in development
  }
  return (
    <section>
      <Link to="/">
        <i className="invisible text-6xl text-neutral-100 px-1 bg-neutral-600 absolute md:visible">
          <BiArrowBack className="text-3xl text-neutral-100 " />
        </i>
      </Link>
      <article className="w-full md:px-14 flex flex-col">
        <div className="flex flex-col items-center">
          <h2 className="text-3xl font-bold text-neutral-100">Message Me</h2>
          <p className="text-xl text-neutral-300 leading-7 mt-5">I am currently looking for a full time position but available for collaborative casual projects. If you have any questions or would like to get in touch, please send me a direct email here! </p>
        </div>
        {/* action="/josevaz/sendmsg" method="post" */}
        <form onSubmit={handleSubmit} className='mt-5 flex flex-col text-neutral-900'>
          <textarea value={message} name="message" rows="6" placeholder="Tell me anything!" className="p-1" onChange={onChange} />
          <label ></label>
          <input value={email} className='my-3 px-1' type="email" name="email" placeholder="E-Mail" onChange={onChange} ></input>
          <input value={phone} className='px-1' type="text" name="phone" placeholder="Phone Number" onChange={onChange} ></input>
          <button type="submit" className="w-full bg-neutral-700 rounded text-xl font-bold h-10 mt-4 text-neutral-300 
          hover:bg-neutral-600 hover:text-neutral-200 hover:shadow-lg
          active:bg-neutral-700 active:text-neutral-300 shadow-none">Send!</button>
        </form>
      </article>
    </section>
  )
}

export default Contact;

//update later to take organization name, and a hiring-reporterror-collaboffer check box
//comment out SendEmail for view (Front-end) pure react testing